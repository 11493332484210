






















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IRecipe} from '@/models/iRecipe';

@Component({
  components: {}
})
export default class SocialShare extends Vue {
  @Prop({required: true})
  recipe!: IRecipe;
}
