import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import NotFound404 from '../views/NotFound404.vue'
import Recipe from '@/views/Recipe.vue';
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Recipe
    },
    {
        path: '/recept/:id',
        name: 'Recipe',
        component: Recipe
    },
    {
        path: '*',
        name: 'NotFound404',
        component: NotFound404
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
