





















































































import {Vue, Watch} from 'vue-property-decorator';
import {IRecipe} from '@/models/iRecipe';
import moment from 'moment';
import SocialShare from '@/components/SocialShare.vue';
import EventBus from '@/EventBus';
import Component from 'vue-class-component'

Component.registerHooks([
  'metaInfo'
])

@Component({
  components: {
    SocialShare
  }
})
export default class Recipe extends Vue {
  recipe: IRecipe | null = null;

  public metaInfo(): any {
    return {
      meta: [
        {name: 'description', content: this.metaDescription}
      ],
      title: 'Hembakt - ' + this.recipe?.title
    };
  }

  get dateDisplay(): string {
    if (this.recipe) {
      return moment(this.recipe.createdAt).format('D MMMM');
    }
    return '';
  }

  get metaDescription(): string {
    if (this.recipe?.body) {
      const html: string = this.recipe?.body;
      const div: HTMLDivElement = document.createElement('div');
      div.innerHTML = html;
      const text: string = div.textContent || div.innerText || '';
      if (text.length > 150) {
        return text.substr(0, 150) + '...';
      }
      return text;
    }
    return '';
  }

  @Watch('recipe')
  onRecipeChanged() {
    EventBus.$emit('recipe-changed', this.recipe?.id);
  }

  @Watch('$route.params.id')
  onIdChange() {
    if (this.$route.params.id) {
      this.recipe = this.getRecipe(this.$route.params.id);
    }
    else {
      this.recipe = Vue.AppStorage.recipes[0];
    }
    if (!this.recipe) {
      this.$router.push({path: '/404'})
    }
  }

  mounted() {
    if (Vue.AppStorage.recipes.length === 0) {
      EventBus.$on('recipes-ready', () => {
        if (Vue.AppStorage.recipes.length > 0) {
          if (this.$route.params.id) {
            this.recipe = this.getRecipe(this.$route.params.id);
          }
          else {
            this.recipe = Vue.AppStorage.recipes[0];
          }
          if (!this.recipe) {
            this.$router.push({path: '/404'})
          }
        }
      })
    }
    else {
      if (this.$route.params.id) {
        this.recipe = this.getRecipe(this.$route.params.id);
      }
      else {
        this.recipe = Vue.AppStorage.recipes[0];
      }
      if (!this.recipe) {
        this.$router.push({path: '/404'})
      }
    }
    EventBus.$emit('recipe-changed', this.recipe?.id);
  }

  getRecipe(recipeId: string): IRecipe {
    return Vue.AppStorage.recipes.find((item: IRecipe) => {
      return item.id === recipeId;
    });
  }
}
