























































import {Component, Vue} from 'vue-property-decorator';
import EventBus from '@/EventBus';
import {IRecipe} from '@/models/iRecipe';

@Component({
  components: {}
})
export default class QuickLinks extends Vue {
  recipes: IRecipe[] = [];

  mounted() {
    this.recipes = Vue.AppStorage.recipes;
    EventBus.$on('recipe-changed', (id: string) => {
      this.recipes.forEach((item) => {
        item.active = item.id === id;
      });
    })
  }
}
