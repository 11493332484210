



























































































import {Component, Vue} from 'vue-property-decorator';
import {IRecipe} from '@/models/iRecipe';
import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import EventBus from '@/EventBus';
import * as Contentful from 'contentful'
import {ContentfulClientApi} from 'contentful'
import AppStorage from '@/AppStorage';
import {IArticle} from '@/models/iArticle';
import Loader from './components/Loader.vue'
import QuickLinks from '@/components/QuickLinks.vue';

@Component({
  components: {
    Loader,
    QuickLinks
  }
})
export default class App extends Vue {
  public loading: boolean = true;
  public storage: AppStorage = Vue.AppStorage;
  contentfulClient: ContentfulClientApi = Contentful.createClient({
    space: this.storage.contentfulSpace,
    accessToken: this.storage.contentfulAccessToken
  })
  article: IArticle | null = null;

  mounted(): void {
    window.setTimeout(() => {
      this.contentfulClient.getEntries({
        content_type: 'recipe',
        order: '-sys.createdAt'
      }).then((response) => {
        this.storage.recipes = this.parseRecipes(response);
        EventBus.$emit('recipes-ready')
        this.loading = false;
      })
    }, 500);

    this.contentfulClient.getEntry('3tBSXhMtyKAJ9oa9eHe5Bz').then((response) => {
      this.article = this.parseArticle(response);
    })
  }

  parseRecipes(response: any): IRecipe[] {
    const recipes: IRecipe[] = [];
    response.items.forEach((item: any) => {
      recipes.push({
        id: item.sys.id,
        title: item.fields.title,
        body: documentToHtmlString(item.fields.body),
        content: item.fields.content.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        image: item.fields.image.fields.file.url,
        createdAt: item.fields.orginalDate || item.sys.createdAt,
        updatedAt: item.sys.updatedAt,
        active: false
      })
    });

    recipes.sort((a: IRecipe, b: IRecipe) => (b.createdAt > a.createdAt) ? 1 : ((a.createdAt > b.createdAt) ? -1 : 0))
    return recipes;
  }

  parseArticle(response: any): IArticle {
    return {
      title: response.fields.title,
      body: documentToHtmlString(response.fields.body),
      image: response.fields.image.fields.file.url,
      createdAt: response.sys.createdAt,
      updatedAt: response.sys.updatedAt
    }
  }
}
