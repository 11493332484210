import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import AppStorage from './AppStorage';
import VueSocialSharing from 'vue-social-sharing'
import BootstrapVue, {BootstrapVueIcons} from 'bootstrap-vue';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false
Vue.AppStorage = new AppStorage();
Vue.prototype.AppStorage = Vue.AppStorage;

Vue.use(VueMeta)
Vue.use(VueSocialSharing);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
